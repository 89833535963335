<template>
    <div class="top-tabs">
        <AtomTabs
            :class="{'top-tabs__item': true}"
            :model-value="deliveryDateIndex"
            :active="!nosTabSelected"
            :items="deliveryDateTabs"
            :title="$t('Matrix.delivery_dates')"
            @update:model-value="emit('update:deliveryDateIndex', $event)"
            @click="deliveryTabClicked"
        />
        <AtomTabs
            v-if="nosTabVisible"
            v-role:not="RoleTypes.SUPPLIER"
            :model-value="deliveryDateNosIndex"
            :class="{
                'top-tabs__nos-tab': true,
                'top-tabs__nos-tab-disabled': nosTabDisabled
            }"
            :items="nosDateTabs"
            :title="$t('Matrix.nos_maintain')"
            :active="Boolean(nosTabSelected)"
            :tooltip="$t('Matrix.nos_2_0_tooltip')"
            @click="nosTabClicked"
        />
    </div>
</template>

<script lang="ts" setup>
import type { TabItem } from '~/composables/types/ui';
import useMatrixWarnings from '~/composables/matrix/useMatrixWarnings';
import { RoleTypes } from '~/composables/types/roleTypes';
import type { MatrixAvailableDeliveryDate } from '~/composables/types/api/searchDiscover/getMatrix';
import useMatrixDateHelper from "~/composables/matrix/useMatrixDateHelper";

const props = defineProps<{
    deliveryDates: TabItem[],
    nosDeliveryDates: TabItem[],
    deliveryDateIndex: number,
    deliveryDateNosIndex: number,
    nosTabVisible: boolean,
    nosTabSelected: boolean,
    nosTabDisabled: boolean,
    cartClosed: boolean,
    matrixDisabled: boolean,
    availableDeliveryDates: MatrixAvailableDeliveryDate[],
    allowCreateDeliveryDate: boolean
}>();

const emit = defineEmits<{
    (e: 'update:nosTabSelected', isActive: boolean, index: number): void,
    (e: 'createNewDeliveryDate'): void,
    (e: 'update:deliveryDateIndex' | 'editDeliveryDate' | 'editNosDeliveryDate', index: number): void,
}>();

const {
    nosNotAvailableWarning,
} = useMatrixWarnings();

const { $hasRole } = useNuxtApp();

const {
    stringToDate,
} = useMatrixDateHelper();

const availableDeliveryDatesExist = computed(() => props.availableDeliveryDates.length > 0);

const deliveryTabClicked = (index: number, event: MouseEvent) => {
    const htmlTarget = event.target as HTMLElement;

    if (index !== props.deliveryDates.length) {
        emit('update:deliveryDateIndex', index);
    }

    if (index === props.deliveryDates.length) {
        event.preventDefault();
        emit('createNewDeliveryDate');
    } else if (
        ((htmlTarget.tagName === 'svg' && htmlTarget.parentElement?.className.includes('interaction')) || htmlTarget.tagName === 'path') &&
        availableDeliveryDatesExist.value) {
        event.preventDefault();
        emit('editDeliveryDate', index);
    }

    if (props.nosTabSelected) {
        emit('update:nosTabSelected', false, index);
    }
};

const deliveryDateTabs = computed((): TabItem[] => {
    const dates: TabItem[] = JSON.parse(JSON.stringify(props.deliveryDates));

    dates.map((date: TabItem) => {
        if (date.totalQuantity) {
            return delete Object.assign(date, {
                label: date.totalQuantity.toString() ?? 0,
                labelIcon: 'basket',
                icon: availableDeliveryDatesExist.value ? date.icon : '',
                tabWarning: props.availableDeliveryDates.length === 0 ||
                    !props.availableDeliveryDates.some(availableDate => {
                        const targetDate = stringToDate(date?.value?.toString() ?? '');
                        const fromDate = stringToDate(availableDate.fromDate);
                        const toDate = stringToDate(availableDate.toDate);

                        return targetDate >= fromDate && targetDate <= toDate;
                    })
            }).totalQuantity;
        }

        return date;
    });

    if (!props.cartClosed && availableDeliveryDatesExist.value && props.allowCreateDeliveryDate && !$hasRole(RoleTypes.SUPPLIER)) {
        dates.push({ icon: 'plus' });
    }

    return dates;
});

const nosDateTabs = computed((): TabItem[] => {
    const dates = JSON.parse(JSON.stringify(props.nosDeliveryDates));

    dates.map((date: any) => {
        if (date.totalQuantity) {
            return delete Object.assign(date, {
                labelType: '',
                label: 'NOS',
                labelIcon: 'check'
            }).totalQuantity;
        }

        return delete Object.assign(date, {
            labelType: 'inactive',
            label: 'NOS',
            labelIcon: ''
        }).totalQuantity;
    });

    return dates;
});

const nosTabClicked = (index: number, event: MouseEvent) => {
    if (props.nosTabDisabled) {
        nosNotAvailableWarning();

        return;
    }

    const htmlTarget = event.target as HTMLElement;

    if (!props.nosTabSelected) {
        emit('update:nosTabSelected', true, index);
    }

    event.preventDefault();

    if (
        ((htmlTarget.tagName === 'svg' && htmlTarget.parentElement?.className.includes('interaction')) || htmlTarget.tagName === 'path') &&
        !props.matrixDisabled
    ) {
        emit('editNosDeliveryDate', index);
    }
};

onMounted(() => {
    const tabIndex = deliveryDateTabs.value.findIndex((obj: TabItem) => obj.label !== undefined);
    emit('update:deliveryDateIndex', tabIndex > 0 ? tabIndex : 0);
});
</script>

<style lang="scss" scoped>
.top-tabs {
    justify-content: space-between;
    width: 100%;
    min-width: rem(152);
    display: flex;

    &__item {
        width: 100%;
    }

    &__nos-tab {
        &:last-child {
            min-width: rem(160);
            margin-left: auto;
        }

        :deep(.tabs__space) {
            display: none;
        }
    }

    &__nos-tab-disabled {
        :deep(.tabs__item) {
            cursor: not-allowed;
            opacity: 0.5;
        }
    }
}
</style>
