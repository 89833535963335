<template>
    <div class="tabs">
        <div v-if="title" class="tabs__title">{{ title }}</div>
        <div ref="content" class="tabs__content">
            <template v-for="(item, i) in items" :key="i">
            <div
                v-if="!props.hideIndex?.includes(i)"
                :class="{
                    tabs__item: true,
                    active: modelValue === i && active,
                    'icon-only': !item.text,
                    'spacing-right--inner spacing-right--xs': item.label,
                    line: lineStyle,
                    box: !lineStyle,
                    disabled: item.disabled,
                    'tabs__item-warning': item.tabWarning,
                }"
                :title="item.text"
                @click="clicked($event, i)"
            >
                <div
                    v-if="item.label"
                    :class="{'tabs__label': true, 'spacing-x--xxs': true, 'inactive': item.labelType === 'inactive'}"
                    data-testid="matrixDeliveryDateTabBasketLabel"
                    @mouseenter="mouseEnterTooltipInfo"
                    @mouseleave="mouseLeaveTooltipInfo"
                >
                {{ item.label }}
                <AtomIcon v-if="item.labelIcon" :name="item.labelIcon" :width="12" :bolding="2"/>
                <div v-if="item.labelType === 'inactive'" :class="`color color-${item.label.toLowerCase().replace(' ', '-')}`"/></div>
                <AtomTooltip
                    v-if="tooltip"
                    :element="showTooltipLabel"
                    :offset-y="tooltipOffset"
                    tooltip-type="absolute"
                >
                    <div class="context-menu">
                        {{ tooltip }}
                    </div>
                </AtomTooltip>
                <div class="text">{{ item.text }}</div>
                <div
                    v-if="item.icon"
                    :class="{'tabs__item__plus-icon': item.icon === 'plus', 'tabs__item__edit-icon': true}"
                    :data-testid="item.icon === 'plus' && 'addDeliveryDateTab'"
                    @click="clicked($event, i)"
                >
                    <AtomIcon
                        v-if="item.icon"
                        :class="{'interaction': item.icon !== 'plus'}"
                        :name="item.icon"
                        :bolding="item.icon === 'plus' ? 2 : 1"
                    />
                </div>
            </div>
            </template>
            <div class="tabs__space" />
        </div>
        <div
            :class="{ tabs__left: true, tabs__arrow: true, visible: leftVisible }"
            @click="scrollLeft"
        >
            <AtomIcon name="arrow-left" />
        </div>
        <div
            :class="{ tabs__right: true, tabs__arrow: true, visible: rightVisible }"
            @click="scrollRight"
        >
            <AtomIcon name="arrow-right" />
        </div>
    </div>
</template>

<script lang="ts" setup>
import type { TabItem } from '~/composables/types/ui';

const props = withDefaults(defineProps<{
    items: TabItem[],
    modelValue?: number,
    title?: string,
    lineStyle?: boolean,
    hideIndex?: number[] | null,
    active?: boolean,
    tooltip?: string | undefined
}>(), {
    title: undefined,
    lineStyle: false,
    modelValue: 0,
    hideIndex: null,
    active: true,
    showLabel: '',
    showLabelAsActiveLabel: false,
    tooltip: undefined
});

const emit = defineEmits<{
    (e: 'click', index: number, event: MouseEvent): void,
    (e: 'update:modelValue', index: number, event: MouseEvent | null): void,
}>();

const content = ref<HTMLElement | undefined>();
const leftVisible = ref(false);
const rightVisible = ref(false);
const showTooltipLabel = ref<HTMLElement | null>(null);
const tooltipOffset:number = 7;
let resizeTimeout: NodeJS.Timeout;
let resizeObserver: ResizeObserver | null = null;

const clicked = (event: MouseEvent, index: number) => {
    if (props.items[index].disabled) {
        return;
    }
    emit('click', index, event);
    if (event.defaultPrevented) {
        return;
    }
    if (index !== props.modelValue) {
        emit('update:modelValue', index, event);
    }
};

const checkOverflow = () => {
    if (!content.value) {
        return;
    }

    if (content.value.scrollWidth > content.value.offsetWidth) {
        leftVisible.value = content.value.scrollLeft > 0;
        rightVisible.value =
            Math.ceil(content.value.scrollLeft) + content.value.offsetWidth <
            content.value.scrollWidth;
    } else {
        leftVisible.value = false;
        rightVisible.value = false;
    }
};

const scrollLeft = () => {
    content.value?.scrollBy({ left: -content.value.offsetWidth, behavior: 'smooth' });
};

const scrollRight = () => {
    content.value?.scrollBy({ left: content.value.offsetWidth, behavior: 'smooth' });
};

const mouseEnterTooltipInfo = (event: MouseEvent) => {
    if (props.tooltip) {
        showTooltipLabel.value = event.target as HTMLElement;
    }
};

const mouseLeaveTooltipInfo = () => {
    if (props.tooltip) {
        showTooltipLabel.value = null;
    }
};

watch(
    () => props.items,
    async () => {
        await nextTick();
        checkOverflow();
    },
);

onMounted(() => {
    if (!content.value) {
        return;
    }

    content.value.addEventListener('scroll', checkOverflow);
    checkOverflow();

    if (ResizeObserver) {
        resizeObserver = new ResizeObserver(() => {
            clearTimeout(resizeTimeout);

            resizeTimeout = setTimeout(() => {
                checkOverflow();
            }, 100);
        });

        resizeObserver.observe(content.value);
    }
});

onBeforeUnmount(() => {
    if (resizeObserver) {
        resizeObserver.disconnect();
    }
    if (content.value) {
        content.value.removeEventListener('scroll', checkOverflow);
    }
});

watchEffect(() => {
    if (props.hideIndex?.includes(props.modelValue)) {
        const indexToEmit = props.items.findIndex((_, i) => !props.hideIndex?.includes(i));
        if (indexToEmit !== -1) {
            emit('update:modelValue', indexToEmit, null);
        }
    }
});
</script>

<style lang="scss" scoped>
.tabs {
    user-select: none;
    overflow: hidden;

    @include helper-font-size(small);
    @include helper-color(text-secondary);
    @include helper-color-bg(white);

    position: relative;

    .color {
        width: rem(12);
        height: rem(12);

        @include helper-border-radius-circle();

        &-nos {
            @include helper-color-bg(label-nos);
        }
    }

    &__title {
        margin-bottom: sp(xxs);
        text-align: left;
        font-weight: fw(medium);
    }

    &__item {
        justify-content: center;
        align-items: center;
        width: 100%;
        min-width: rem(155);
        max-width: rem(200);
        transition: border-color 0.3s, color 0.3s;
        display: flex;

        @include helper-color(text-secondary);
        @include helper-svg-size(16);

        .text {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            max-width: rem(100);
            font-weight: fw(medium);
            font-size: fs(small);
        }

        .svg-icon {
            margin-left: sp(xs);
        }

        &.box {
            cursor: pointer;

            @include helper-border();
            @include helper-border-radius-t(rem(5));
            @include helper-color-bg(light-gray);
            @include helper-color(text-primary);

            &.active {
                border-bottom-color: $setting-color-white;
                cursor: default;

                @include helper-color(state-default);
                @include helper-border-base($setting-color-state-default, solid);
                @include helper-color-bg(white);
            }
        }

        &:not(.active, .disabled):hover {
            @include helper-color(state-hover);
        }

        &.disabled {
            cursor: auto;
        }

        &.icon-only {
            min-width: rem(50);
            width: auto;

            .svg-icon {
                margin-left: 0;
            }
        }

        &.line {
            flex-direction: row-reverse;

            @include helper-border-b();

            .svg-icon {
                margin-right: sp(xs);
                margin-left: 0;
            }

            &.active {
                @include helper-color(state-default);
                @include helper-border-base($setting-color-state-default, solid);
            }
        }

        &__edit-icon {
            cursor: pointer;
        }

        &__plus-icon {
            cursor: pointer;
            width: 100%;
            height: 100%;

            @include helper-flex-centered();
        }
    }

    .tabs__item .tabs__label {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: rem(4);
        border-radius: rem(18);
        font-size: fs(smaller);
        font-weight: fw(medium);
        padding: rem(3) rem(8);
        height: inherit;
        line-height: rem(18);

        @include helper-color(white);
        @include helper-color-bg(label-quantity);

        &.inactive {
            font-weight: fw(medium);
            font-size: fs(smaller);

            @include helper-color(text-primary);
            @include helper-color-bg(white);
            @include helper-border($setting-color-gray-1);
        }

        .svg-icon {
            margin-left: 0;
        }
    }

    :deep(.tooltip__default-content) {
        @include helper-color(black);
    }

    &__content {
        height: rem(40);
        overflow: auto;
        margin-left: rem(1);
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        display: flex;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__space {
        flex-basis: 100%;

        @include helper-border-b();
    }

    &__arrow {
        cursor: pointer;
        position: absolute;
        height: rem(40);
        width: rem(40);
        top: rem(-1);

        @include helper-color-bg(white);
        @include helper-flex-centered();
        @include helper-svg-size(24);
    }

    &__left {
        left: rem(-40);
        transition: left 0.25s;

        &.visible {
            left: 0;
        }
    }

    &__right {
        right: rem(-40);
        transition: right 0.25s;

        &.visible {
            right: 0;
        }
    }

    .tabs__item.tabs__item-warning {
        @include helper-color-bg(alert-warning);
    }
}
</style>
