<template>
    <div
        ref="matrixTable"
        :class="{ 'cursor-not-allowed': !enabled, matrix: true, 'quick-select': quickSelection && enabled, 'nos-tab-selection': nosTabSelected }"
        tabindex="-1"
        oncontextmenu="return false;"
        @keydown="keyDownTable"
        @contextmenu="rowHeaderContextMenu"
    >
        <div
            ref="rowContainer"
            :class="{ 'matrix-column matrix-column-left': true, 'cell-disabled': !enabled }"
        >
            <div class="matrix__column-header matrix-cell top-left border-left header-sticky">
                <div class="matrix__column-header-container">
                    <span class="matrix__column-header-row matrix__column-header-row-first">
                        {{ $t('Matrix.table_header') }}
                    </span>
                    <span
                        class="matrix__column-header-row matrix__column-header-row-first matrix__column-header-text">
                        <span
                            v-if="activeTabDeadlineDate !== '' && !nosTabSelected"
                            class="matrix__column-header-dates">
                            {{ 'Deadline: ' }}
                            <LazyAtomMatrixDateLabel
                                :text="useDateFormat(new Date(activeTabDeadlineDate ?? ''), 'DD.MM.YYYY')"
                                :color="useMatrixDateHelper().checkDeliveryDateDeadline(activeTabDeadlineDate)"
                                :deadline-date="useDateFormat(new Date(activeTabDeadlineDate ?? ''), 'DD.MM.YYYY')"
                            />
                        </span>
                        <span v-else-if="nosTabSelected">{{ $t('Matrix.quantity') }}</span>
                    </span>
                </div>
            </div>
            <template v-for="(section, sectionIndex) in data.sections" :key="sectionIndex">
                <div
                     class="matrix__section-header matrix-cell border-left"
                     @click="toggleSection(sectionIndex, section.key?.toString())"
                >
                    <div class="matrix__row-header--arrow">
                        <LazyAtomIcon
                            name="arrow-down-3"
                            :class="{
                                'matrix__row-header--collapse-arrow': true,
                                'matrix__row-header--arrow-collapsed': !getSectionOpened(sectionIndex, section.key?.toString())
                            }"
                        />
                    </div>
                    <div class="matrix__row-header--text-container">
                        <LazyAtomIcon
                            v-if="branchClustersActive && groupByIndex === 0"
                            name="cluster"
                            width="24"
                            class="matrix__row-header--cluster-icon"
                        />
                        <span
                            :title="`${section.label}
                        ${branchClustersActive && sectionIndex !== data.sections.length - 1? ' - ' + branchClustersActive.branchClusterName : ''}`"
                            class="matrix__row-header--text">
                            {{ section.label }}
                            {{ (branchClustersActive && sectionIndex !== data.sections.length - 1) ?
                            `- ${branchClustersActive.branchClusterName}` :
                            '' }}
                        </span>
                        <template
                            v-if="warningsStore.get().hasOwnProperty(section.key) && warningsStore.get()[section.key].hasOwnProperty('section')"
                        >
                            <LazyAtomProductAvailableSupplier
                                v-for="warning in warningsStore.get()[section.key]['section']"
                                :key="warning.key"
                                :available="false"
                                :icon="false"
                                :text="$t('Matrix.min_of', [warning.quantity, warning.minQuantity])"
                                @mouseenter="mouseEnterMinWarning($event, warning)"
                                @mouseleave="mouseLeaveMinWarning"
                            />
                        </template>
                        <div
                            v-if="section.info"
                            class="matrix__row-header--info"
                            data-testid="matrixRowHeaderInfo"
                            @mouseenter="mouseEnterInfo($event, 0, sectionIndex)"
                            @mouseleave="mouseLeaveInfo"
                        >
                            <LazyAtomIcon name="info" bolding="2" />
                        </div>
                    </div>
                    <LazyAtomMatrixClusterBranchesInfo
                        v-if="branchClustersActive && groupByIndex === 0"
                        :branches="branchClustersActive.clusters?.[sectionIndex]?.branches ?? null"
                        :branch-names="branchClustersActive.clusters?.[sectionIndex]?.branchNames ?? null"
                        :missing-modules="getMissingModules(sectionIndex, data.sections.length)"
                    />
                </div>
                <div
                    v-for="(rowHeader, rowIndex) in data.rowHeaders"
                    v-show="getSectionOpened(sectionIndex, section.key?.toString())"
                    :key="rowIndex"
                    :class="{'matrix__row-header': true,
                    'matrix-cell': true,
                    'border-thick-right': true,
                    'row-selected': isRowSelected(sectionIndex, rowIndex),
                    }"
                >
                    <div class="matrix__row-header--block" />
                    <div
                        :class="{ 'matrix__row-header--label': true, 'matrix__row-header--cluster-align': branchClustersActive}"
                        :title="rowHeader.label"
                    >
                        <LazyAtomIcon
                            v-if="branchClustersActive && groupByIndex === 1"
                            name="cluster"
                            width="24"
                            class="matrix__row-header--cluster-icon"
                        />
                        <span
                            :title="`${rowHeader.label}
                            ${branchClustersActive && rowIndex !== data.rowHeaders.length - 1? ' - ' + branchClustersActive.branchClusterName : ''}`"
                            class="matrix__row-header--text">
                            {{ rowHeader.label }}
                            {{ (branchClustersActive && rowIndex !== data.rowHeaders.length - 1) ?
                            `- ${branchClustersActive.branchClusterName}` :
                            '' }}
                        </span>
                        <LazyAtomMatrixClusterBranchesInfo
                            v-if="branchClustersActive && groupByIndex === 1"
                            :branches="branchClustersActive.clusters?.[rowIndex]?.branches ?? null"
                            :branch-names="branchClustersActive.clusters?.[rowIndex]?.branchNames ?? null"
                            :missing-modules="getMissingModules(rowIndex, data.rowHeaders.length)"
                            :icon="true"
                        />
                    </div>
                    <template
                        v-if="warningsStore.get().hasOwnProperty(section.key) && warningsStore.get()[section.key].hasOwnProperty(rowHeader.key)">
                        <LazyAtomProductAvailableSupplier
                            v-for="warning in warningsStore.get()[section.key][rowHeader.key]"
                            :key="warning.key"
                            :available="false"
                            :icon="false"
                            :text="$t('Matrix.min_of', [warning.quantity, warning.minQuantity])"
                            @mouseenter="mouseEnterMinWarning($event, warning)"
                            @mouseleave="mouseLeaveMinWarning"
                        />
                    </template>
                    <div
                        v-if="rowHeader.info"
                        class="matrix__row-header--info"
                        data-testid="matrixRowHeaderInfo"
                        @mouseenter="mouseEnterInfo($event, rowIndex, sectionIndex)"
                        @mouseleave="mouseLeaveInfo"
                        @click="tooltipTouchClick($event, rowIndex, sectionIndex)"
                    >
                        <AtomIcon name="info" bolding="2" />
                    </div>
                    <variante-in-sortiment-icon
                        v-if="!branchClustersActive"
                        class="matrix__row--icon"
                        :api-base-url="config.public.ccApiCartServiceUrl"
                        :auth-token="accessToken" :model-code="modelCode"
                        :color-id="getHeaderCellData(sectionIndex, rowIndex)?.colorId"
                        :catalog-id="catalogId"
                        :cart-id="cartId"
                        :waren-end-empfaenger="getHeaderCellData(sectionIndex, rowIndex)?.partnerId"
                                                />
                </div>
            </template>

            <div ref="rowRectangle" class="selection-rect" />
            <div>
                <div class="matrix-cell matrix__column-totals headline">
                    <span v-if="!nosTabSelected">{{ $t('Matrix.total_deliveries') + deliveryDates
                        }}</span>
                    <span v-else>{{ $t('Matrix.quantity') }}</span>
                </div>
            </div>
        </div>

        <div ref="columnsScrollViewport" class="matrix-columns-container">
            <div ref="cellContainer" class="matrix-columns is-scrollbooster-content">
                <div
                    v-for="(columnHeader, columnIndex) in data.columnHeaders"
                    :key="columnIndex"
                    class="matrix-column"
                    :class="{ 'cell-disabled': !enabled }"
                    data-testid="matrixColumn"
                >
                    <div class="matrix-cell matrix__column-header header-sticky">
                        <div class="matrix__column-header-container">
                            <div class="matrix__column-header-row">{{ columnHeader.label }}</div>
                            <div class="matrix__column-header-row matrix__column-header-text">
                                {{ getTotalPiecesForColumn(columnIndex) }}
                            </div>
                        </div>
                    </div>
                    <div
                        v-for="(section, sectionIndex) in data.sections" :key="sectionIndex"
                        class="matrix-column">
                        <div
                            class="matrix__section-header matrix-cell"
                            @click="toggleSection(sectionIndex, section.key?.toString())"
                        >
                            <div v-if="groupByIndex && columnIndex === 0" class="matrix__row-header--labels">
                                <LazyAtomProductLabel
                                    v-for="(label, index) in getSectionLabels(section)"
                                    :key="index"
                                    :text="label"
                                    :render-tooltip-in-body="true"
                                />
                                <LazyAtomProductScaleLabel
                                    v-if="getSectionLabels(section).includes('MS')"
                                    :text="getSectionScalePrices(section)"
                                    :data="section"
                                />
                            </div>
                        </div>
                        <div class="matrix-cells">
                            <div
                                v-for="(cell, rowIndex) in section.columns[columnIndex].cells"
                                v-show="getSectionOpened(sectionIndex, section.key?.toString())"
                                :key="rowIndex"
                                :class="{
                                    'matrix-cell': true,
                                    'matrix-cell__locked': !shouldProcessCell(cell),
                                    selected: isSelected(columnIndex, getYIndex(sectionIndex, rowIndex)),
                                    'no-right-border': columnIndex === data.columnHeaders.length - 1,
                                    'row-selected': isRowSelected(sectionIndex, rowIndex)
                                }"
                                @click="cellClicked(columnIndex, getYIndex(sectionIndex, rowIndex))"
                            >
                                <div
                                    v-if="shouldProcessCell(cell) && (!cell.cartClosed || nosTabSelected)"
                                    class="matrix-cell__content">
                                    <div
                                        v-if="
                                            showInputX === columnIndex &&
                                            showInputY === getYIndex(sectionIndex, rowIndex)
                                        "
                                        @click="$event.stopPropagation()"
                                    >
                                        <LazyAtomNumberInput
                                            :input-type="isTouchDevice() ? 'number' : 'text'"
                                            :step="cell.packagingUnit"
                                            :model-value="inlineValue"
                                            size="s"
                                            :min="minQuantity"
                                            :allow-zero="true"
                                            :auto-focus="true"
                                            :side-buttons="true"
                                            @focus="onFocusTab"
                                            @update:model-value="updateInlineQuantities"
                                            @on-tab="onTab"
                                            @on-enter="onEnter"
                                        />
                                    </div>
                                    <template v-else>
                                        <div class="matrix-cell__quantities">
                                            <div
                                                :class="{
                                                    'matrix-cell__quantity': true,
                                                    'matrix-cell__no-quantity': noActive &&
                                                                                !cellHasQuantity(cell.branchId, cell.partnerId, cell.gtin)
                                                }">
                                                <LazyAtomProductAvailability
                                                    v-if="noActive"
                                                    :status="cell?.stockStatus ?? null"
                                                    :re-availability-date="cell?.reAvailabilityDate ?? null"
                                                    :show-text="false"
                                                    @mouseenter="availabilityInfoEnter($event, getCellAvailabilityInfo(cell))"
                                                    @mouseleave="availabilityInfoLeave()"
                                                />
                                                <span>
                                                    {{ (nosTabSelected && showOrderTarget ?
                                                    getNosStandardStock(cell.branchId, cell.partnerId, cell.gtin) :
                                                    getCartQuantity(cell.branchId, cell.partnerId, cell.gtin)) || ''
                                                    }}
                                                </span>
                                            </div>
                                            <div
                                                :class="{
                                                    'matrix-cell__quantity-pu': true,
                                                    rounded:
                                                        Math.abs(
                                                            quickSelectQuantity(
                                                                cell.packagingUnit,
                                                                nosTabSelected && showOrderTarget ?
                                                                getNosStandardStock(cell.branchId, cell.partnerId, cell.gtin) :
                                                                getCartQuantity(cell.branchId, cell.partnerId, cell.gtin)
                                                                ),
                                                        ) !== quickSelectionCount,
                                                }"
                                            >
                                                {{ quickSelectAddition && quickSelectionCount > 0 ? '+' : ''
                                                }}{{ quickSelectQuantity(
                                                cell.packagingUnit,
                                                nosTabSelected && showOrderTarget ?
                                                    getNosStandardStock(cell.branchId, cell.partnerId, cell.gtin) :
                                                    getCartQuantity(cell.branchId, cell.partnerId, cell.gtin)
                                            ) }}
                                            </div>
                                        </div>
                                        <div
                                            class="matrix__prices"
                                        >
                                            <span class="matrix__price-type">
                                                {{ cellSelectionFinished ? getPriceForGtinAndBranch(cell, columnIndex, rowIndex, sectionIndex) : ''
                                                }}
                                            </span>
                                        </div>
                                        <div v-if="nosTabSelected && !showOrderTarget" class="matrix-cell__quantity-target">
                                            <span>{{ $t('Matrix.default_stock_short') }}</span>
                                            <span>{{ getNosStandardStock(cell.branchId, cell.partnerId, cell.gtin) || '0'
                                                }}</span>
                                        </div>
                                        <div v-if="nosTabSelected && showOrderTarget" class="matrix-cell__quantity-target">
                                            <span>{{ $t('Matrix.minimal_quantity_short') }}</span>
                                            <span>{{ getCartQuantity(cell.branchId, cell.partnerId, cell.gtin) || '0'
                                                }}</span>
                                        </div>
                                        <div
                                            v-if="!nosTabSelected && getCartModuleQuantity(cell.branchId, cell.partnerId, cell.gtin)"
                                            class="matrix-cell__quantity-target"
                                        >
                                            <span>{{ getCartModuleQuantity(cell.branchId, cell.partnerId, cell.gtin) || ''
                                                }}</span>
                                        </div>
                                    </template>
                                </div>
                                <div
                                    v-else-if="nosTabSelected && !cell.writable"
                                    @mouseenter="mouseEnterLockedInfo($event, cell)"
                                    @mouseleave="mouseLeaveLockedInfo"
                                >
                                    <div class="matrix-cell__not-editable">
                                        <span
                                            v-if="
                                                showOrderTarget && getNosStandardStock(cell.branchId, cell.partnerId, cell.gtin) !== 0 &&
                                                getNosStandardStock(cell.branchId, cell.partnerId, cell.gtin) !== -1
                                            "
                                            class="matrix-cell__not-editable-value"
                                        >
                                            ({{ getNosStandardStock(cell.branchId, cell.partnerId, cell.gtin) || '0'
                                            }})
                                        </span>
                                        <span
                                            v-else-if="
                                                getCartQuantity(cell.branchId, cell.partnerId, cell.gtin) !== 0 &&
                                                getCartQuantity(cell.branchId, cell.partnerId, cell.gtin) !== -1
                                            "
                                            class="matrix-cell__not-editable-value"
                                        >
                                            ({{ getCartQuantity(cell.branchId, cell.partnerId, cell.gtin) || '0'
                                            }})
                                        </span>
                                        <AtomIcon name="lock-2" class="matrix-cell__not-editable-icon" />
                                    </div>
                                </div>
                                <div
                                    v-else-if="cell.cartClosed" class="matrix-cell__not-editable"
                                    @mouseenter="mouseEnterLockedInfo($event, cell)"
                                    @mouseleave="mouseLeaveLockedInfo"
                                >
                                    <template
                                        v-if="getCartQuantity(cell.branchId, cell.partnerId, cell.gtin)">
                                        <span class="matrix-cell__not-editable-value">
                                            ({{ getCartQuantity(cell.branchId, cell.partnerId, cell.gtin)
                                            }})
                                        </span>
                                        <AtomIcon name="lock-2" class="matrix-cell__not-editable-icon" />
                                    </template>
                                </div>
                                <div
                                    v-else class="matrix-cell__content"
                                    @mouseenter="mouseEnterLockedInfo($event, cell)"
                                    @mouseleave="mouseLeaveLockedInfo"
                                >
                                    <div class="matrix-cell__no-not-editable">
                                        <LazyAtomProductAvailability
                                            v-if="noActive"
                                            :status="cell.stockStatus"
                                            :re-availability-date="cell.reAvailabilityDate"
                                            :show-text="false"
                                        />
                                        <AtomIcon name="lock-2" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="matrix__column-totals matrix__column-no-borders" />
                    </div>
                </div>
                <div ref="cellRectangle" class="selection-rect" />
            </div>

            <div class="matrix-columns-scrollbar-wrapper">
                <div ref="columnsScrollBar" class="matrix-columns-scrollbar">
                    <div />
                </div>
            </div>
        </div>

        <div :class="{ 'cell-disabled': !enabled }" class="matrix-column matrix-column--pieces">
            <div class="matrix-cell matrix__column-header header-sticky">
                <div class="matrix__column-header-container">
                    <span class="matrix__column-header-row">{{ $t('Matrix.pieces') }}</span>
                    <span class="matrix__column-header-row matrix__column-header-text" data-testid="matrixPiecesSumHeader">
                        {{ !showOrderTarget ? sum?.productsCount : sum?.productsStandardStockCount || 0 }}
                    </span>
                </div>
            </div>
            <div
                v-for="(section, sectionIndex) in data.sections" :key="sectionIndex"
                class="matrix-column-row">
                <div
                    class="matrix__section-header matrix-cell"
                    @click="toggleSection(sectionIndex, section.key?.toString())"
                >
                    {{ totalPiecesForSection(sectionIndex) }}
                </div>
                <div class="matrix-cells">
                    <div
                        v-for="rowIndex in data.rowHeaders.length"
                        v-show="getSectionOpened(sectionIndex, section.key?.toString())"
                        :key="rowIndex"
                        :class="{'matrix-cell': true,
                        'border-thick-left': true,
                        'row-selected': isRowSelected(sectionIndex, rowIndex - 1)}"
                    >
                        <div class="matrix-cell__content">
                            {{ totalPiecesForRow(sectionIndex, rowIndex - 1) }}
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="matrix-cell matrix__column-totals">
                    <span>{{ !showOrderTarget ? sum?.productsCount : sum?.productsStandardStockCount || 0
                        }}</span>
                </div>
            </div>
        </div>

        <template v-if="!nosTabSelected">
            <div
                v-for="(type, index) in ['purchasePrice', 'sellPrice']"
                :key="index"
                :class="{ 'cell-disabled': !enabled }"
                class="matrix-column matrix-column--sum"
            >
                <div class="matrix-cell matrix__column-header header-sticky">
                    <div class="matrix__column-header-container">
                        <div class="matrix__column-header-row">
                            <span>{{ type === 'sellPrice' ? $t('General.upe') : $t('General.net_ek')
                                }}</span>
                            <LazyAtomIcon
                                name="sum" width="22"
                                class="matrix__column-header-container-icon" />
                        </div>
                        <div
                            :class="{'matrix__column-header-row': true, 'matrix__column-header-text': true, 'matrix-column__column-blur': !cellSelectionFinished}">
                            {{ cellSelectionFinished ? useMoneyFormat(type === 'sellPrice' ? sum?.sumSellPrice : sum?.sumPurchasePrice) || 0 : 0
                            }}
                        </div>
                    </div>
                </div>
                <div
                    v-for="(section, sectionIndex) in data.sections"
                    :key="sectionIndex"
                    :class="{'matrix-column-row': true, 'matrix-column__column-blur': !cellSelectionFinished}"
                >
                    <div
                        class="matrix__section-header matrix-cell border-right"
                        @click="toggleSection(sectionIndex, section.key?.toString())"
                    >
                        {{ cellSelectionFinished ? useMoneyFormat(totalPriceForSection(sectionIndex, type === 'sellPrice' ? 'sellPrice' : 'purchasePrice')) : 0
                        }}
                    </div>
                    <div class="matrix-cells">
                        <div
                            v-for="rowIndex in data.rowHeaders.length"
                            v-show="getSectionOpened(sectionIndex, section.key?.toString())"
                            :key="rowIndex"
                            :class="{'matrix-cell': true, 'row-selected': isRowSelected(sectionIndex, rowIndex - 1)}"
                        >
                            <div class="matrix-cell__content">
                                {{ cellSelectionFinished ? useMoneyFormat(totalPriceForRow(sectionIndex, rowIndex - 1, type === 'sellPrice' ? 'sellPrice' : 'purchasePrice')) : 0
                                }}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div
                        :class="{'matrix-cell': true, 'matrix__column-totals': true, 'matrix-column__column-blur': !cellSelectionFinished}">
                        <div>
                            {{ cellSelectionFinished ? useMoneyFormat(type === 'sellPrice' ? sum?.sumSellPrice : sum?.sumPurchasePrice) || 0 : 0
                            }}
                        </div>
                    </div>
                </div>
            </div>
            <div
                :class="{ 'cell-disabled': !enabled }"
                class="matrix-column matrix-column--sum matrix-column--sum-percent-from"
            >
                <div class="matrix-cell matrix__column-header header-sticky">
                    <div class="matrix__column-header-container">
                        <div class="matrix__column-header-row">
                            <span>{{ $t('Cart.percent_from') }}</span>
                        </div>
                        <div
                            :class="{'matrix__column-header-row': true, 'matrix__column-header-text': true, 'matrix-column__column-blur': !cellSelectionFinished}">
                            {{ cellSelectionFinished ? getTotalFromPercentage() : 0 }}
                        </div>
                    </div>
                </div>
                <div
                    v-for="(section, sectionIndex) in data.sections"
                    :key="sectionIndex"
                    :class="{'matrix-column-row': true, 'matrix-column__column-blur': !cellSelectionFinished}">
                    <div
                        class="matrix__section-header matrix-cell border-right"
                        @click="toggleSection(sectionIndex, section.key?.toString())"
                    >
                        {{ cellSelectionFinished ? getSectionFromPercentage(sectionIndex) : 0 }}
                    </div>
                    <div class="matrix-cells">
                        <div
                            v-for="rowIndex in data.rowHeaders.length"
                            v-show="getSectionOpened(sectionIndex, section.key?.toString())"
                            :key="rowIndex"
                            :class="{'matrix-cell': true, 'row-selected': isRowSelected(sectionIndex, rowIndex - 1)}"
                        >
                            <div class="matrix-cell__content">
                                {{ cellSelectionFinished ? getRowFromPercentage(sectionIndex, rowIndex - 1) : 0
                                }}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div
                        :class="{'matrix-cell': true, 'matrix__column-totals': true, 'matrix-column__column-blur': !cellSelectionFinished}">
                        <div>{{ cellSelectionFinished ? getTotalFromPercentage() : 0 }}</div>
                    </div>
                </div>
            </div>
        </template>
        <LazyAtomTooltip :element="tooltipWarningElement">
            {{ tooltipWarning ? $t(tooltipWarning.translationKey, [tooltipWarning.minQuantity]) : ''
            }}
        </LazyAtomTooltip>

        <LazyAtomTooltip
            :element="contextTooltipElement"
            :offset-x="contextTooltipOffsetX"
            :offset-y="contextTooltipOffsetY"
            :is-context-menu="true"
            :no-padding="true"
            @outside-pointer-up="contextTooltipElement = null">

            <div class="context-menu">
                <div class="context-menu__item" @click="selectAll">
                    <div class="context-menu__description">{{ $t('Matrix.select_all') }}</div>
                    <div class="context-menu__hotkey">{{ $t(`Matrix.modifier_key_${osName}`) }} A
                    </div>
                </div>
                <div class="context-menu__item" @click="copy">
                    <div class="context-menu__description">{{ $t('Matrix.copy') }}</div>
                    <div class="context-menu__hotkey">{{ $t(`Matrix.modifier_key_${osName}`) }} C
                    </div>
                </div>
                <div class="context-menu__item" @click="cut">
                    <div class="context-menu__description">{{ $t('Matrix.cut') }}</div>
                    <div class="context-menu__hotkey">{{ $t(`Matrix.modifier_key_${osName}`) }} X
                    </div>
                </div>
                <div :class="{'context-menu__item': true, disabled: !canPaste}" @click="paste">
                    <div class="context-menu__description">{{ $t('Matrix.paste') }}</div>
                    <div class="context-menu__hotkey">{{ $t(`Matrix.modifier_key_${osName}`) }} V
                    </div>
                </div>
            </div>
        </LazyAtomTooltip>

        <LazyAtomTooltip
            :element="tooltipElement"
            :offset-x="40"
        >
            <LazyMoleculeMatrixInfoTooltip
                v-if="tooltipInfo"
                :tooltip-info="tooltipInfo"
                :group-by-index="groupByIndex"
                :nos-tab-selected="nosTabSelected"
            />
        </LazyAtomTooltip>

        <LazyAtomTooltip
            :element="lockedTooltipElement"
            :no-padding="false"
            tooltip-type="absolute"
        >
            <div class="context-menu">
                {{ tooltipLockedInfo }}
            </div>
        </LazyAtomTooltip>
        <LazyAtomTooltip
            v-if="useStore().isNoStore()" :element="availabilityTooltipElement"
            :no-padding="false" tooltip-type="absolute">
            <div class="context-menu">{{ availabilityTooltipText }}</div>
        </LazyAtomTooltip>
    </div>
</template>

<script lang="ts" setup>
// @ts-ignore
import ScrollBooster from 'scrollbooster';
import type {
    MissingMatrixModules,
    NosQuantityUpdate,
    QuantityUpdate
} from '~/composables/types/api/cartConditions/matrix';
import type {
    MatrixCell,
    MatrixContentData,
    MatrixContentHeaderInfo,
    MatrixHeaderSection
} from '~/composables/types/matrix/useMatrixContentDataTypes';
import type {
    ScrollBoosterApi,
    ScrollBoosterInfo
} from '~/composables/types/service/scrollBoosterTypes';
import type { MatrixSumProps } from '~/composables/types/api/searchDiscover/getMatrix';
import {
    type BranchClusterState,
    useBranchClusterStore
} from '~/composables/stores/useBranchClusterStore';
import { useMatrixPricesStore } from '~/composables/stores/useMatrixPricesStore';
import useMoneyFormat from '~/composables/utils/useMoneyFormat';
import useDateFormat from '~/composables/utils/useDateFormat';
import { isTouchDevice } from '~/composables/utils/useBrowserUtils';
import useMatrixDateHelper from '~/composables/matrix/useMatrixDateHelper';
import useStore from '~/composables/service/useStore';
import { RoleTypes } from "~/composables/types/roleTypes";

type TooltipWarning = {
    minQuantity: string,
    translationKey: string,
}

type ExtendedUpdateForNos = {
    standardStock?: number;
}

type ProcessQuantitiesObject = ExtendedUpdateForNos & {
    branchId: string;
    partnerId: string;
    gtin: string;
    quantity: number;
    colorId: string;
}

const props = defineProps<{
    data: MatrixContentData,
    deliveryDates: string,
    activeTabDeadlineDate: string | null,
    getCartQuantity: (branchId: string, partnerId: string, gtin: string) => number,
    getNosStandardStock: (branchId: string, partnerId: string, gtin: string) => number,
    getCartModuleQuantity: (branchId: string, partnerId: string, gtin: string) => number | null,
    minQuantity: number,
    quickSelection: boolean,
    quickSelectionCount: number,
    enabled: boolean,
    groupByIndex: number,
    sum: MatrixSumProps,
    scrollableZone: HTMLElement | null,
    nosTabSelected: boolean,
    showOrderTarget: boolean,
    catalogId: string,
    modelCode: string,
    cartId: string | undefined,
}>();

const emit = defineEmits<{
    (e: 'quantityUpdated', items: QuantityUpdate[] | NosQuantityUpdate[], increase: boolean): void,
}>();

const warningsStore = useMinQuantityWarningsStore();
const { $emitter, $t } = useNuxtApp();
const toasts = useToasts();
const matrixPricesStore = useMatrixPricesStore();
const showInputX = ref(-1);
const showInputY = ref(-1);
const inlineValue = ref(0);
const isTooltipOpen = ref(false);
const sameIndex = ref('');
const tooltipWarningElement = ref<HTMLElement | null>(null);
const tooltipWarning = ref<TooltipWarning | null>(null);
const tooltipElement = ref<HTMLElement | null>(null);
const contextTooltipElement = ref<HTMLElement | null>(null);
const lockedTooltipElement = ref<HTMLElement | null>(null);
const availabilityTooltipElement = ref<HTMLElement | null>(null);
const contextTooltipOffsetX = ref(0);
const contextTooltipOffsetY = ref(0);
const tooltipInfo = ref<MatrixContentHeaderInfo>();
const tooltipLockedInfo = ref<string>('');
const availabilityTooltipText = ref<string>('');
const quickSelectAddition = ref(true);
const EMIT_TIMEOUT = 500;
const STANDARD_STOCK_ERROR_TIMEOUT = 10000;

const selectionStartX = ref(-1);
const selectionStartY = ref(-1);
const selectionEndX = ref(-1);
const selectionEndY = ref(-1);
const matrixTable = ref<HTMLElement | null>(null);
const isMacOS = ref(/Mac/i.test(navigator.userAgent));
const osName = ref(isMacOS.value ? 'osx' : 'win');
const headerClassname = 'matrix__section-header';
const columnsScrollViewport = ref();
const columnsScrollBar = ref();
const { getAccessToken } = ccMicroFrontend();
const accessToken = getAccessToken();
const config = useRuntimeConfig();
const { $hasRole } = useNuxtApp();

const shouldProcessCell = (cell: MatrixCell) => (props.nosTabSelected ? !cell.blocked && !cell.nosBlocked && cell.writable : !cell.blocked);

const hasCells = computed(() => props.data.sections.some(
    (section) => section.columns.some(
        (column) => column.cells.some(
            (cell) => shouldProcessCell(cell)
        )
    )
));

const selectedRows = ref<boolean[]>(Array(props.data.rowHeaders.length * props.data.sections.length).fill(false));
const canPaste = ref(false);

let lastRowHeaderSelection: number[] = [];
let addToRowSelection = false;
let originalSelectedRows: boolean[] = [];
let clipboard: { [key: string]: (number | null)[] } = {};
let rowSelectionMoved: boolean = false;
let firstSelectedRowsCount: number = 0;
let scrollBooster: ScrollBoosterApi | null = null;
let emitTimeout: ReturnType<typeof setTimeout> | null;
let scrollWidth = 0;
const noActive = useStore().isNoStore();
const cellSelectionFinished = ref(true);

const closedSections: Ref<string[]> = ref([]);
const {
    getCachedData: getClosedBranchesStorage,
    updateCachedData: updateClosedBranchesStorage
} = useLocalStorageCache<string[]>(LocalStorageNameTypes.CLOSED_BRANCHES_STORAGE);
const closedSectionsColors = ref<number[]>([]);

const rowsPerSection = computed(() => props.data.rowHeaders.length);
const totalRows = computed(() => props.data.rowHeaders.length * props.data.sections.length);
const totalColumns = computed(() => props.data.columnHeaders.length);
const contextMenuClicked = ref(false);

const {
    container: cellContainer,
    rectangle: cellRectangle,
    registerCallbacks: registerCellCallbacks
} = useSelectionRect();

const {
    container: rowContainer,
    rectangle: rowRectangle,
    registerCallbacks: registerRowCallbacks
} = useSelectionRect();

const { getBranchClusterTemplate } = useBranchClusterStore();
const branchClustersActive: Ref<BranchClusterState['branchClusterTemplate'] | null> = ref(null);

watch(() => getBranchClusterTemplate(), () => {
    branchClustersActive.value = getBranchClusterTemplate(true);
});

const elementIsHeaderRow = (element: HTMLElement) => (
    element.classList.contains(headerClassname) || Boolean(element.closest(`.${headerClassname}`))
);

const cellHasQuantity = (
    branchId: string,
    partnerId: string,
    gtin: string
) => props.getCartQuantity(branchId, partnerId, gtin) > 0;

const getCellUPEPrice = (gtin: string, defaultPrice: number) => matrixPricesStore.getUPEPriceForCell(gtin, defaultPrice);

const isMouseEventInsideElement = (event: MouseEvent, rect: DOMRect): boolean => event.clientY >= rect.top && event.clientY <= rect.bottom;

const getMissingModules = (sectionIndex: number, arrayLength: number): MissingMatrixModules => {
    if (sectionIndex === arrayLength - 1) {
        return {
            branches: null,
            branchNames: null
        };
    }

    return {
        branches: branchClustersActive?.value?.clusters?.[sectionIndex]?.missingModuleBranches ?? null,
        branchNames: branchClustersActive?.value?.clusters?.[sectionIndex]?.missingModuleBranchNames ?? null
    };
};

const getRowHeaderIndex = (event: MouseEvent): number => {
    if (!rowContainer.value) {
        return -1;
    }

    const rowHeaderCells = rowContainer.value.querySelectorAll('.matrix__row-header');

    for (let i = 0; i < rowHeaderCells.length; ++i) {
        const rect = rowHeaderCells[i].getBoundingClientRect();

        if (isMouseEventInsideElement(event, rect)) {
            return i;
        }
    }

    return -1;
};

const clearAndSelectRowsWithShift = (clickedRowIndex: number) => {
    for (let i = 1; i < lastRowHeaderSelection.length; ++i) {
        selectedRows.value.splice(lastRowHeaderSelection[i], 1, false);
    }
    const first = lastRowHeaderSelection[0];
    lastRowHeaderSelection = [];
    for (let i = Math.min(clickedRowIndex, first); i <= Math.max(clickedRowIndex, first); ++i) {
        selectedRows.value.splice(i, 1, true);
        lastRowHeaderSelection.push(i);
    }
    if (clickedRowIndex < first) {
        lastRowHeaderSelection.reverse();
    }
};

const handleShiftKeySelection = (event: MouseEvent) => {
    if (selectedRows.value.some(row => row)) {
        const clickedRowIndex = getRowHeaderIndex(event);
        if (clickedRowIndex !== -1) {
            clearAndSelectRowsWithShift(clickedRowIndex);
        }
    }
};

const onStartRowSelection = (x: number, y: number, event: MouseEvent): boolean => {
    contextMenuClicked.value = false;
    matrixTable.value?.focus({ preventScroll: true });

    if (elementIsHeaderRow(event.target as HTMLElement)) {
        return false;
    }

    if (event.shiftKey) {
        handleShiftKeySelection(event);

        return false;
    }

    addToRowSelection = osName.value === 'osx' ? event.metaKey : event.ctrlKey;
    if (addToRowSelection) {
        originalSelectedRows = JSON.parse(JSON.stringify(selectedRows.value));
    }

    return true;
};

const isSectionActive = (y: number): boolean => {
    if (!props.groupByIndex) {
        const sectionKey = props.data.sections[Math.floor(y / rowsPerSection.value)]?.key?.toString();

        return !closedSections.value.includes(sectionKey);
    }

    const sectionIndex = Math.floor(y / rowsPerSection.value);

    return !closedSectionsColors.value.includes(sectionIndex);
};

const getSectionOpened = (index: number, key: string | undefined): boolean => (!props.groupByIndex && key ?
    !closedSections.value.includes(key) :
    !closedSectionsColors.value.includes(index));

const getSelectedRowsCount = (): number => selectedRows.value.reduce((count, value) => count + (value ? 1 : 0), 0);


const onUpdateRowSelection = (rect: DOMRect, startX: number, startY: number, move: boolean) => {
    if (!rowContainer.value || contextMenuClicked.value) {
        return;
    }

    const rowHeaderCells = rowContainer.value.querySelectorAll('.matrix__row-header');
    const containerRect = rowContainer.value.getBoundingClientRect();

    let [start, end] = [-1, -1];
    const result = Array(totalRows.value).fill(false);

    for (let i = 0; i < rowHeaderCells.length; ++i) {
        const cellRect = rowHeaderCells[i].getBoundingClientRect();
        const top = cellRect.y - containerRect.top;
        const bottom = cellRect.bottom - containerRect.top;

        if (rect.bottom < top) {
            break;
        }

        if (start === -1) {
            if (rect.top >= top && rect.top <= bottom) {
                start = i;
                end = start;
            } else if (i % rowsPerSection.value === 0) {
                if (i === 0 && rect.bottom >= top && rect.top < top) {
                    start = i;
                    end = start;
                } else if (rect.top < top && rect.bottom >= rowHeaderCells[i - 1].getBoundingClientRect().y - containerRect.y) {
                    start = i;
                }
            }
        }

        if (start !== -1) {
            end = i;
        }
    }

    if (move && !rowSelectionMoved) {
        rowSelectionMoved = true;
        firstSelectedRowsCount = getSelectedRowsCount();
    }

    if (end !== -1) {
        lastRowHeaderSelection = [];
        for (let i = start; i <= end; ++i) {
            if (!isSectionActive(i)) {
                continue;
            }

            lastRowHeaderSelection.push(i);
            result[i] = getSelectedRowsCount() === 1 && !move ? !selectedRows.value[i] : true;

            if (!move && rowSelectionMoved && getSelectedRowsCount() === 1) {
                firstSelectedRowsCount === 0 ? (result[i] = true) : false;
            }
        }
        if (rect.top === startY) {
            lastRowHeaderSelection.reverse();
        }
    }

    if (addToRowSelection) {
        result.forEach((_, i) => (result[i] = originalSelectedRows[i] ? !result[i] : result[i]));
    }

    !move ? (rowSelectionMoved = false) : null;
    selectedRows.value = result;
};

const onFinishRowSelection = (rect: DOMRect, startX: number, startY: number) => {
    onUpdateRowSelection(rect, startX, startY, false);
};

const onStartCellSelection = (x: number, y: number, event: PointerEvent): boolean => {
    if (!props.quickSelection || elementIsHeaderRow(event.target as HTMLElement)) {
        return false;
    }

    quickSelectAddition.value = event.pointerType !== 'mouse' || event.button === 0;

    if (elementIsHeaderRow(event.target as HTMLElement)) {
        return false;
    }

    if (!props.nosTabSelected) {
        cellSelectionFinished.value = false;
    }

    return true;
};

const getCell = (x: number, y: number): MatrixCell => {
    const sectionIndex = Math.floor(y / rowsPerSection.value);
    const section = props.data.sections[sectionIndex];
    return section?.columns[x]?.cells[y % rowsPerSection.value];
};

const getHeaderCellData = (
    sectionIndex: number, rowIndex: number
): {colorId: string, partnerId: string} | null => {
    const section = props.data.sections[sectionIndex];
    const column = section.columns[0];
    const relativeRowIndex = rowIndex % rowsPerSection.value;
    const cell = column.cells[relativeRowIndex];
    const returnData = { colorId: cell.colorId, partnerId: cell.partnerId }
    return returnData || null;
};

const quickSelectQuantity = (packagingUnit: number, current: number): number => {
    if (props.nosTabSelected && !props.showOrderTarget) {
        packagingUnit = 1;
    }
    const mod = props.quickSelectionCount % packagingUnit;
    if (quickSelectAddition.value) {
        let value = props.quickSelectionCount;
        if (mod !== 0) {
            value = props.quickSelectionCount + packagingUnit - mod;
        }
        if (current + value < props.minQuantity && !isTouchDevice()) {
            return props.minQuantity;
        }

        return value;
    }

    let value = -props.quickSelectionCount;
    if (mod !== 0) {
        value = -props.quickSelectionCount + mod - packagingUnit;
    }
    value = Math.max(value, -current);
    if (current + value < props.minQuantity) {
        return -current;
    }

    return value;
};

const onUpdateCellSelection = (rect: DOMRect) => {
    if (!cellContainer.value) {
        return;
    }

    const firstColumnCells = Array.from(cellContainer.value
        .querySelector('.matrix-column')!
        .querySelectorAll('.matrix-cells'));

    const containerRect = cellContainer.value.getBoundingClientRect();

    const getCellX = (x: number): number => Math.floor((x / cellContainer.value!.scrollWidth) * totalColumns.value);

    const getCellY = (y: number, isTop: boolean): { section: boolean; row: number } => {
        for (let i = 0; i < firstColumnCells.length; ++i) {
            const cellsRect = (firstColumnCells[i] as HTMLElement).getBoundingClientRect();
            const top = cellsRect.top - containerRect.top;

            if (y < top) {
                return {
                    section: true,
                    row: (i * rowsPerSection.value) - (isTop ? 0 : 1)
                };
            }

            const bottom = cellsRect.bottom - containerRect.top;
            if (y > bottom) {
                continue;
            }

            return {
                section: false,
                row: (i * rowsPerSection.value) +
                    (Math.floor(((y - top) / (bottom - top)) * rowsPerSection.value))
            };
        }

        return {
            section: false,
            row: (rowsPerSection.value * firstColumnCells.length) - 1
        };
    };

    selectionStartX.value = Math.min(Math.max(getCellX(rect.left), 0), totalColumns.value - 1);
    selectionEndX.value = Math.min(Math.max(getCellX(rect.right), 0), totalColumns.value - 1);

    const startY = getCellY(rect.top, true);
    const endY = getCellY(rect.bottom, false);

    if (startY.section && endY.section && (endY.row - startY.row <= 1 || endY.row === -1)) {
        selectionStartY.value = -1;
        selectionEndY.value = -1;
    } else {
        selectionStartY.value = Math.min(Math.max(startY.row, 0), totalRows.value - 1);
        selectionEndY.value = Math.min(Math.max(endY.row, 0), totalRows.value - 1);
    }
};

const calculateQuantityByType = (quantity: number, amount: number, inputQuantity?: number) => inputQuantity ?? Math.max(0, quantity + amount);
const inputQuantityAmount = (
    cell: MatrixCell, nosStandardStock: number, quantity: number, inputQuantity?: number
) => inputQuantity ?? quickSelectQuantity(cell.packagingUnit, props.nosTabSelected && props.showOrderTarget ? nosStandardStock : quantity);

const calculateNewQuantity = (
    value: number, newValue: number, changingProp: number
) => ((value > 0 && newValue === 0 && newValue >= changingProp) ? 0 : changingProp);

const showStandardStockError = () => {
    toasts.toasts.length > 0 ? toasts.clear('error') : null;
    toasts.add({
        type: 'warning',
        headline: $t('Matrix.sb_min_quantity_warning_head'),
        text: $t('Matrix.sb_min_quantity_warning_text'),
        timeout: STANDARD_STOCK_ERROR_TIMEOUT
    });
};

const checkZeroInput = (
    zeroInput: boolean, value: number, same: number
): number => (zeroInput ? value : same);

const calculateNewValues = (nosStandardStock: number, calcValue: number, self: number): number => {
    if (!(props.nosTabSelected && props.showOrderTarget) && nosStandardStock === 0) {
        return calcValue;
    }

    return self;
};

const checkValue = (value: string) => value ?? '';

const getProcessQuantitiesObject = (
    cell: MatrixCell, newQuantity: number, extendedUpdateForNos: ExtendedUpdateForNos
): ProcessQuantitiesObject => ({
    branchId: cell?.branchId || '',
    partnerId: cell?.partnerId || '',
    gtin: cell?.gtin || '',
    quantity: newQuantity,
    colorId: cell?.colorId || '',
    ...extendedUpdateForNos
});

const processQuantities = (cell: MatrixCell, inputQuantity?: number): ProcessQuantitiesObject | null => {
    const branchId = checkValue(cell?.branchId);
    const partnerId = checkValue(cell?.partnerId);
    const gtin = checkValue(cell?.gtin);
    const quantity = props.getCartQuantity(branchId, partnerId, gtin);

    const isStandardStock = props.nosTabSelected && props.showOrderTarget;
    let extendedUpdateForNos = {};
    let nosStandardStock = 0;

    let newNosStandardStock = 0;
    let newQuantity = 0;

    if (props.nosTabSelected) {
        nosStandardStock = props.getNosStandardStock(cell.branchId, partnerId, cell.gtin);
        newNosStandardStock = Math.max(0, newNosStandardStock + nosStandardStock);
        newQuantity = quantity;
    }

    const amount = inputQuantityAmount(cell, nosStandardStock, quantity, inputQuantity);

    newQuantity = calculateNewValues(nosStandardStock,
        calculateQuantityByType(quantity, amount, inputQuantity),
        newQuantity);
    newNosStandardStock = calculateNewValues(nosStandardStock,
        calculateQuantityByType(newNosStandardStock, amount, inputQuantity),
        newNosStandardStock);

    if (isStandardStock) {
        newNosStandardStock = calculateQuantityByType(nosStandardStock, amount, inputQuantity);
        newQuantity = calculateNewQuantity(nosStandardStock, newNosStandardStock, newQuantity);

        if (newNosStandardStock < quantity && newNosStandardStock !== 0) {
            showStandardStockError();
            newNosStandardStock = nosStandardStock;
        }
        newQuantity = checkZeroInput((nosStandardStock === 0 || newNosStandardStock === 0), newNosStandardStock, newQuantity);
    }

    if (props.nosTabSelected && !props.showOrderTarget) {
        newQuantity = calculateQuantityByType(quantity, amount, inputQuantity);

        if (newQuantity > nosStandardStock && quantity !== 0) {
            showStandardStockError();
            newQuantity = nosStandardStock;
        }

        newNosStandardStock = checkZeroInput((newQuantity === 0), newQuantity, newNosStandardStock);
    }

    if (props.nosTabSelected) {
        extendedUpdateForNos = { standardStock: newNosStandardStock };
    }

    if (quantity !== newQuantity || nosStandardStock !== newNosStandardStock) {
        return getProcessQuantitiesObject(cell, newQuantity, extendedUpdateForNos);
    }

    return null;
};

const processCell = (cell: MatrixCell, y: number): ProcessQuantitiesObject | null => {
    if (shouldProcessCell(cell) && isSectionActive(y)) {
        return processQuantities(cell);
    }

    return null;
};

const checkCellAvailabilities = (
    cellQuantity: number,
    availableQuantity: number | null,
    reAvailabilityDate: string | null
) => {
    let noAvailableQuantityError = false;
    let quantity = cellQuantity;

    if (!reAvailabilityDate && (availableQuantity && cellQuantity > availableQuantity)) {
        quantity = availableQuantity;
        noAvailableQuantityError = true;
    }

    if (noAvailableQuantityError) {
        toasts.clear('warning');
        toasts.add({
            type: 'warning',
            headline: $t('Matrix.available_quantities_exceeded_headline'),
            text: $t('Matrix.available_quantities_exceeded_text'),
            autoHide: false
        });
    }

    return quantity;
};

const hasInvalidFinishCellSelection = (): boolean => (
    selectionStartX.value === -1 ||
    selectionEndX.value === -1 ||
    selectionStartY.value === -1 ||
    selectionEndY.value === -1
);

const resetFinishCellSelectionValues = () => {
    quickSelectAddition.value = true;
    selectionStartX.value = -1;
    selectionEndX.value = -1;
    selectionStartY.value = -1;
    selectionEndY.value = -1;
};

const onFinishCellSelection = (rect: DOMRect) => {
    onUpdateCellSelection(rect);

    if (hasInvalidFinishCellSelection()) {
        return;
    }

    const result: QuantityUpdate[] | NosQuantityUpdate[] = [];

    for (let x = selectionStartX.value; x <= selectionEndX.value; ++x) {
        for (let y = selectionStartY.value; y <= selectionEndY.value; ++y) {
            const cell = getCell(x, y);
            const cellResult = processCell(cell, y);

            if (cellResult) {
                if (noActive) {
                    cellResult.quantity = checkCellAvailabilities(cellResult.quantity, cell.availableQuantity, cell.reAvailabilityDate);
                }

                result.push(cellResult);
            }
        }
    }

    if (result.length) {
        emit('quantityUpdated', result, quickSelectAddition.value);
    }

    if (!result.length) {
        cellSelectionFinished.value = true;
    }

    resetFinishCellSelectionValues();
};

const onColumnsScrollBarScroll = () => {
    if (scrollBooster && !scrollBooster.isDragging) {
        scrollBooster.scrollTo({ x: columnsScrollBar.value.scrollLeft });
    }
};

const cleanupScrollBooster = () => {
    if (columnsScrollBar?.value?.removeEventListener('scroll', onColumnsScrollBarScroll)) {
        columnsScrollBar?.value?.removeEventListener('scroll', onColumnsScrollBarScroll);
    }
};

const handleWheelScroll = (event: WheelEvent) => {
    if (event.shiftKey) {
        columnsScrollBar.value.scrollLeft += event.deltaY;
    } else if (event.deltaX) {
        columnsScrollBar.value.scrollLeft += event.deltaX;
    }
};

const updateScrollBarDisplay = () => {
    if (cellContainer.value && cellContainer.value.scrollWidth > cellContainer.value.offsetWidth) {
        columnsScrollBar.value.style.display = 'block';
    }
};

const updateScrollBarWidth = (marginX: number) => {
    const columnsScrollBarContent = columnsScrollBar.value.querySelector('div');
    if (columnsScrollBarContent) {
        columnsScrollBarContent.style.width = `${(cellContainer.value?.scrollWidth ?? 0) - marginX}px`;
    }
};

const setupScrollBooster = () => {
    scrollBooster = new ScrollBooster({
        viewport: columnsScrollViewport.value,
        content: cellContainer.value,
        scrollMode: 'transform',
        direction: 'horizontal',
        emulateScroll: true,
        pointerMode: 'touch',
        bounce: false,
        shouldScroll(info: ScrollBoosterInfo, event: TouchEvent): boolean {
            return event.target === columnsScrollBar.value;
        },
        onWheel(info: ScrollBoosterInfo, event: WheelEvent) {
            handleWheelScroll(event);
        },
        onUpdate(info: ScrollBoosterInfo) {
            const columnsScrollBarMarginX = 32;

            if (!cellContainer.value && scrollBooster) {
                cleanupScrollBooster();

                return;
            }

            if (info.isDragging) {
                columnsScrollBar.value.scrollLeft = info.position.x;
            }

            if (info.isMoving) {
                return;
            }

            updateScrollBarDisplay();
            updateScrollBarWidth(columnsScrollBarMarginX);
        }
    });

    columnsScrollBar.value.addEventListener('scroll', onColumnsScrollBarScroll);
};

const triggerScrollBoosterPlugin = (destroy: boolean = false) => {
    if (!cellContainer.value) {
        return;
    }

    if (scrollBooster) {
        scrollBooster.destroy();
    }

    if (destroy) {
        cleanupScrollBooster();

        return;
    }

    setupScrollBooster();
};

const getRowQuantities = (rowIndex: number): (number | null)[] => Array.from({ length: totalColumns.value }, (_, columnIndex) => {
    const cell = getCell(columnIndex, rowIndex);
    const isOrderTargetCellAvailable = props.nosTabSelected && props.showOrderTarget;

    if (shouldProcessCell(cell)) {
        const cellQuantity = isOrderTargetCellAvailable ?
            props.getNosStandardStock(cell.branchId, cell.partnerId, cell.gtin) :
            props.getCartQuantity(cell.branchId, cell.partnerId, cell.gtin);

        return cellQuantity > 0 ? cellQuantity : 0;
    }

    return null;
});

const isSelected = (x: number, y: number): boolean => x >= selectionStartX.value && x <= selectionEndX.value &&
    y >= selectionStartY.value && y <= selectionEndY.value;
const closeInlineInput = () => {
    showInputX.value = -1;
    showInputY.value = -1;
};

const showInlineInput = (x: number, y: number) => {
    const cell = getCell(x, y);

    if (cell) {
        inlineValue.value = props.getCartQuantity(cell.branchId, cell.partnerId, cell.gtin);
        showInputX.value = x;
        showInputY.value = y;
    }
};

const toggleInlineInput = (x: number, y: number) => {
    if (showInputX.value === x && showInputY.value === y) {
        closeInlineInput();
    } else {
        showInlineInput(x, y);
    }
};

const cellClicked = (x: number, y: number) => {
    if (!props.quickSelection) {
        toggleInlineInput(x, y);
    }
};

const selectAll = (event: Event) => {
    event.preventDefault();
    event.stopPropagation();

    selectedRows.value = Array.from({ length: totalRows.value }, (_, i) => isSectionActive(i));
    contextTooltipElement.value = null;
};

const refreshInlineInput = () => {
    const x = showInputX.value;
    const y = showInputY.value;

    if (x === -1 || y === -1) {
        return;
    }

    const cell = getCell(x, y);

    showInputX.value = -1;
    showInputY.value = -1;
    inlineValue.value = props.getCartQuantity(cell.branchId, cell.partnerId, cell.gtin);

    setTimeout(() => {
        showInputX.value = x;
        showInputY.value = y;
    }, 0);
};

const clearRow = (rowIndex: number) => {
    const result = Array.from({ length: totalColumns.value }, (_, columnIndex) => {
        const cell = getCell(columnIndex, rowIndex);

        return {
            gtin: cell.gtin,
            colorId: cell.colorId,
            branchId: cell.branchId,
            partnerId: cell.partnerId,
            quantity: 0
        };
    });

    emit('quantityUpdated', result, false);
    refreshInlineInput();
};

const copy = (event: Event, doCut = false) => {
    event.preventDefault();
    event.stopPropagation();
    const firstSelectedIndex = selectedRows.value.indexOf(true);
    if (firstSelectedIndex === -1) {
        return;
    }

    clipboard = {};
    for (let rowIndex = 0; rowIndex < selectedRows.value.length; ++rowIndex) {
        if (selectedRows.value[rowIndex]) {
            clipboard[rowIndex - firstSelectedIndex] = getRowQuantities(rowIndex);
            if (doCut) {
                clearRow(rowIndex);
            }
        }
    }
    canPaste.value = Object.keys(clipboard).length > 0;
    contextTooltipElement.value = null;
};

const cut = (event: Event) => {
    copy(event, true);
};

const shouldAddToResult = (cell: MatrixCell, key: string, columnIndex: number): boolean => {
    if (cell) {
        if (props.nosTabSelected) {
            return Boolean(!cell.blocked && !cell.nosBlocked && cell.writable);
        }

        return !cell.blocked && clipboard[key]?.[columnIndex] !== null;
    }

    return false;
};

const processClipboardData = (keys: string[], offset: number): QuantityUpdate[] => {
    const result: QuantityUpdate[] = [];
    for (const key of keys) {
        const rowIndex = parseInt(key, 10) + offset;
        for (let columnIndex = 0; columnIndex < totalColumns.value; ++columnIndex) {
            const cell = getCell(columnIndex, rowIndex);
            let nosTargetCellResult = {};
            const newQuantitiesToUpdate = processQuantities(
                cell,
                clipboard[key][columnIndex] != null ? Number(clipboard[key][columnIndex]) : 0
            );
            if (shouldAddToResult(cell, key, columnIndex) && newQuantitiesToUpdate) {
                if (props.nosTabSelected && 'standardStock' in newQuantitiesToUpdate) {
                    nosTargetCellResult = {
                        standardStock: newQuantitiesToUpdate.standardStock ?? 0
                    };
                }

                result.push({
                    gtin: cell.gtin,
                    branchId: cell.branchId,
                    colorId: cell.colorId,
                    partnerId: cell.partnerId,
                    quantity: newQuantitiesToUpdate.quantity,
                    ...nosTargetCellResult
                });
            }
        }
    }

    return result;
};

const paste = (event: Event) => {
    event.preventDefault();
    event.stopPropagation();
    const keys = Object.keys(clipboard);
    const offset = selectedRows.value.indexOf(true);
    if (offset === -1 || keys.length === 0) {
        return;
    }

    const result = processClipboardData(keys, offset);

    emit('quantityUpdated', result, quickSelectAddition.value);
    refreshInlineInput();
    contextTooltipElement.value = null;
};

const keyDownTable = (event: KeyboardEvent) => {
    const isMatrixTableActive = document.activeElement === matrixTable.value;
    const isCtrlKey = osName.value === 'osx' ? event.metaKey : event.ctrlKey;

    if (isMatrixTableActive && isCtrlKey && !$hasRole(RoleTypes.SUPPLIER)) {
        switch (event.key) {
        case 'x':
            cut(event);
            break;
        case 'c':
            copy(event);
            break;
        case 'v':
            paste(event);
            break;
        case 'a':
            selectAll(event);
            break;
        default:
            break;
        }
    }
};

const rowHeaderContextMenu = (event: MouseEvent) => {
    if ($hasRole(RoleTypes.SUPPLIER)) {
        return;
    }

    contextMenuClicked.value = true;

    if (!cellContainer.value || !rowContainer.value) {
        return;
    }

    const cellContainerRect = cellContainer.value.getBoundingClientRect();
    if (event.clientX >= cellContainerRect.x) {
        return;
    }

    const index = getRowHeaderIndex(event);
    if (index === -1) {
        return;
    }

    event.preventDefault();

    if (!selectedRows.value.some(x => x) || !selectedRows.value[index]) {
        selectedRows.value = Array(totalRows.value).fill(false);
        selectedRows.value.splice(index, 1, true);
    }

    const rowHeaderCells = rowContainer.value.querySelectorAll('.matrix__row-header');
    const targetRowHeader = rowHeaderCells[index] as HTMLElement;

    contextTooltipElement.value = targetRowHeader;
    const rect = targetRowHeader.getBoundingClientRect();
    contextTooltipOffsetX.value = event.clientX - rect.x;
    contextTooltipOffsetY.value = event.clientY - rect.y;
};

const getYIndex = (sectionIndex: number, rowIndex: number) => rowIndex + (sectionIndex * rowsPerSection.value);

const tooltipTouchClick = (event: MouseEvent, rowIndex: number, sectionIndex: number) => {
    if (isTouchDevice()) {
        const currentIndex = `${sectionIndex}${rowIndex}`;

        if (isTooltipOpen.value == false) {
            mouseEnterInfo(event, rowIndex, sectionIndex);
            isTooltipOpen.value = true;
            sameIndex.value = currentIndex;

        } else if (isTooltipOpen.value == true && sameIndex.value !== currentIndex) {
            mouseLeaveInfo();
            mouseEnterInfo(event, rowIndex, sectionIndex);
            sameIndex.value = currentIndex;

        } else if (isTooltipOpen.value == true && sameIndex.value === currentIndex) {
            mouseLeaveInfo();
            isTooltipOpen.value = false;
        }
    }
};

const mouseEnterInfo = (event: MouseEvent, rowIndex: number, sectionIndex: number) => {
    const info: MatrixContentHeaderInfo | undefined = props.data?.rowHeaders?.[0]?.info ?
        props.data.rowHeaders[rowIndex].info :
        props.data.sections[sectionIndex].info;

    const sellPrice = matrixPricesStore.getSmallestUPEPriceOfSection(rowIndex, sectionIndex);
    tooltipInfo.value = info;

    if (tooltipInfo.value) {
        tooltipInfo.value.sellPrice = sellPrice ?? info?.sellPrice;
    }

    tooltipElement.value = event.target as HTMLElement;
};

const mouseLeaveInfo = () => {
    tooltipElement.value = null;
    isTooltipOpen.value = false;
};

const mouseEnterLockedInfo = (event: MouseEvent, cell: MatrixCell) => {
    if (!cell.tooltip) {
        return;
    }

    let translatedTooltip = '';
    if (Array.isArray(cell.tooltip)) {
        cell.tooltip.forEach((tooltip) => {
            if (tooltip) {
                const [tooltipKey, tooltipParams] = Array.isArray(tooltip) ? tooltip : [tooltip, null];
                translatedTooltip += `${$t(`Matrix.${tooltipKey}`, tooltipParams)} `;
            }
        });
    } else {
        translatedTooltip += $t(`Matrix.${cell.tooltip}`);
    }

    tooltipLockedInfo.value = translatedTooltip;
    lockedTooltipElement.value = event.target as HTMLElement;
};

const mouseLeaveLockedInfo = () => {
    lockedTooltipElement.value = null;
};

const getCellAvailabilityInfo = (cell: MatrixCell) => {
    if (cell.reAvailabilityDate) {
        const reAvailableDate = useMatrixDateHelper().formatDateString(cell.reAvailabilityDate, 'DD.MM.YYYY');
        return $t('Matrix.available_quantities_tooltip_date', [cell.availableQuantity, reAvailableDate]);
    }

    return $t('Matrix.available_quantities_tooltip', [cell.availableQuantity]);
};

const availabilityInfoEnter = (event: MouseEvent, infoText: string) => {
    if (event.target instanceof HTMLElement) {
        availabilityTooltipText.value = infoText;
        availabilityTooltipElement.value = event.target;
    }
};

const availabilityInfoLeave = () => {
    availabilityTooltipElement.value = null;
};

const mouseEnterMinWarning = (event: MouseEvent, warning: any) => {
    tooltipWarningElement.value = event.target as HTMLElement;
    tooltipWarning.value = warning;
};

const mouseLeaveMinWarning = () => {
    tooltipWarningElement.value = null;
};

const updateInlineQuantity = (value: number | string | undefined) => {
    if (typeof value === 'number') {
        const cell = getCell(showInputX.value, showInputY.value);

        if (cell) {
            const newQuantitiesToUpdate = processQuantities(cell, value);

            if (newQuantitiesToUpdate) {
                if (noActive) {
                    newQuantitiesToUpdate.quantity = checkCellAvailabilities(
                        newQuantitiesToUpdate.quantity,
                        cell.availableQuantity,
                        cell.reAvailabilityDate
                    );
                }

                emit('quantityUpdated', [newQuantitiesToUpdate], false);
            }
        }
    }
};

const updateInlineQuantities = (value: number | string | undefined) => {
    const handleUpdate = () => {
        updateInlineQuantity(value);
    };

    if (emitTimeout) {
        clearTimeout(emitTimeout);
    }

    emitTimeout = setTimeout(handleUpdate, props.nosTabSelected ? EMIT_TIMEOUT : 0);
};

const tabForward = (currentX: number, currentY: number) => {
    let x = currentX + 1;
    let y = currentY;
    if (x >= totalColumns.value) {
        x = 0;
        y += 1;
        if (y >= totalRows.value) {
            y = 0;
            x = 0;
        }
    }

    if (!getCell(x, y)) {
        tabForward(x, y);
    } else {
        showInlineInput(x, y);
    }
};

const tabBackwards = (currentX: number, currentY: number) => {
    let x = currentX - 1;
    let y = currentY;
    if (x < 0) {
        x = totalColumns.value - 1;
        y -= 1;
        if (y < 0) {
            y = totalRows.value - 1;
            x = totalColumns.value - 1;
        }
    }

    if (!getCell(x, y)) {
        tabBackwards(x, y);
    } else {
        showInlineInput(x, y);
    }
};

const inlineInputFirst = () => {
    if (props.quickSelection || !props.enabled || !hasCells.value) {
        return;
    }
    tabForward(-1, 0);
};

const onEnter = () => {
    const timeOut = !props.nosTabSelected ? 0 : EMIT_TIMEOUT;

    setTimeout(() => {
        closeInlineInput();
    }, timeOut);
};

const onFocusTab = (event: FocusEvent) => {
    if (!(event.target instanceof HTMLElement)) {
        return;
    }
    const targetParent = event.target.closest('.matrix-cell');
    if (!(targetParent instanceof HTMLElement)) {
        return;
    }
    const scrollContainer = document.querySelector('.matrix-columns-container');
    const scrollContent = document.querySelector('.matrix-columns');
    if (!(scrollContainer instanceof HTMLElement) || !(scrollContent instanceof HTMLElement)) {
        return;
    }
    const targetRect = targetParent.getBoundingClientRect();
    const containerRect = scrollContainer.getBoundingClientRect();
    if (targetRect.left >= containerRect.left && targetRect.right <= containerRect.right) {
        return;
    }
    let scrollOffset = 0;
    if (targetRect.left < containerRect.left) {
        scrollWidth = 0;
        scrollOffset = scrollWidth;
    } else if (targetRect.right > containerRect.right) {
        scrollWidth += targetRect.width;
        scrollOffset = scrollContainer.scrollLeft + scrollWidth;
    }
    const maxScroll = scrollContent.scrollWidth - containerRect.width;
    scrollOffset = Math.max(0, Math.min(scrollOffset, maxScroll));
    scrollBooster?.scrollTo({ x: scrollOffset, y: 0 });
};

const onTab = (event: KeyboardEvent) => {
    event.preventDefault();
    const timeOut = !props.nosTabSelected ? 0 : EMIT_TIMEOUT;

    if (!hasCells.value) {
        return;
    }

    setTimeout(() => {
        if (event.shiftKey) {
            tabBackwards(showInputX.value, showInputY.value);
        } else {
            tabForward(showInputX.value, showInputY.value);
        }
    }, timeOut);
};

const shouldDisplayPrice = (x: number, y: number, section: number, cell: MatrixCell): boolean => {
    if (props.nosTabSelected) {
        return false;
    }

    if (x === 0) {
        return true;
    }

    y = getYIndex(section, y);

    let prev_x = x - 1;
    let previous: MatrixCell | null = null;

    while (prev_x >= 0 && !(previous = getCell(prev_x, y)).purchasePrice) {
        --prev_x;
    }

    return (!previous || previous.purchasePrice === undefined || previous.purchasePrice !== cell.purchasePrice);
};

const getPriceForGtinAndBranch = (cell: MatrixCell, columnIndex: number, rowIndex: number, sectionIndex: number) => {
    cell.purchasePrice = matrixPricesStore.getEkSinglePriceForGtinAndBranch(cell.gtin, cell.originalBranchId) ?? undefined;

    return computed(() => {
        const display = shouldDisplayPrice(columnIndex, rowIndex, sectionIndex, cell);
        return display && cell.purchasePrice ? useMoneyFormat(cell.purchasePrice) : null;
    });
};

const getTotalPiecesForColumn = (columnIndex: number): number => {
    return props.data.sections
        .flatMap((section, sectionIndex) =>
            section.columns[columnIndex].cells.map((_, cellIndex) => {
                const cell = getCell(columnIndex, (sectionIndex * section.columns[columnIndex].cells.length) + cellIndex);
                return props.getCartQuantity(cell.branchId, cell.partnerId, cell.gtin) * (cell.branchCluster?.branches.length ?? 1);
            })
        )
        .reduce((total, quantity) => total + quantity, 0);
};

const totalPiecesForRow = (sectionIndex: number, rowIndex: number): number => {
    const row = getYIndex(sectionIndex, rowIndex);

    return Array.from({ length: totalColumns.value }, (_, i) => getCell(i, row))
        .filter(cell => shouldProcessCell(cell) || cell.cartClosed || (props.nosTabSelected && !cell.writable))
        .reduce((total, cell) => {
            let value = 0;
            if (props.showOrderTarget) {
                const standardStock = props.getNosStandardStock(cell.branchId, cell.partnerId, cell.gtin);
                if (standardStock > 0) {
                    value = standardStock;
                }

                return total + value;
            }
            const cartQuantity = props.getCartQuantity(cell.branchId, cell.partnerId, cell.gtin) * (cell.branchCluster?.branches.length ?? 1);
            if (cartQuantity > 0) {
                value = cartQuantity;
            }

            return total + value;
        }, 0);
};

const totalPiecesForSection = (sectionIndex: number): number => Array.from(
    { length: rowsPerSection.value }, (_, i) => totalPiecesForRow(sectionIndex, i)
).reduce((total, piecesForRow) => total + piecesForRow, 0);

const calculatePrice = (cell: MatrixCell, priceType: 'purchasePrice' | 'sellPrice', quantity: number): number => {
    if (!cell || !shouldProcessCell(cell) || cell.cartClosed) {
        return 0;
    }

    let price = 0;
    if (priceType === 'purchasePrice') {
        price = matrixPricesStore.getEkSinglePriceForGtinAndBranch(cell.gtin, cell.originalBranchId) ?? cell.purchasePrice ?? 0;
    } else {
        if (cell.sellPrice !== undefined) {
            price = getCellUPEPrice(cell.gtin, cell.sellPrice ?? 0);
        }
    }
    const branchFactor = cell.branchCluster?.branches?.length ?? 1;

    return quantity * branchFactor * price;
};

const totalPriceForRow = (sectionIndex: number, rowIndex: number, priceType: 'purchasePrice' | 'sellPrice'): number => {
    const row = getYIndex(sectionIndex, rowIndex);
    const total = Array.from({ length: totalColumns.value }, (_, colIndex) => {
        const cell = getCell(colIndex, row);
        const cellQuantity = props.getCartQuantity(cell.branchId, cell.partnerId, cell.gtin);
        if (cellQuantity > 0) {
            return calculatePrice(cell, priceType, cellQuantity);
        }

        return 0;
    }).reduce((sum, price) => sum + price, 0);

    return total;
};

const totalPriceForSection = (sectionIndex: number, priceType: 'purchasePrice' | 'sellPrice'): number => {
    const total = Array.from(
        { length: rowsPerSection.value },
        (_, rowIndex) => totalPriceForRow(sectionIndex, rowIndex, priceType)
    ).reduce((sum, rowTotal) => sum + rowTotal, 0);

    return total;
};

const isRowSelected = (sectionIndex: number, rowIndex: number) => selectedRows.value[getYIndex(sectionIndex, rowIndex)];

const toggleSection = (sectionIndex: number, sectionKey: string | undefined) => {
    if (!sectionKey) {
        return;
    }

    const sections: (string | number)[] = props.groupByIndex ? closedSectionsColors.value : closedSections.value;

    const index = sections.indexOf(props.groupByIndex ? sectionIndex : sectionKey);

    if (index > -1) {
        sections.splice(index, 1);
    } else {
        sections.push(props.groupByIndex ? sectionIndex : sectionKey);
    }

    if (!props.groupByIndex) {
        updateClosedBranchesStorage(closedSections.value);
    }
};

const getRowFromPercentage = (sectionIndex: number, rowIndex: number) => {
    const sectionUpe = totalPriceForRow(sectionIndex, rowIndex, 'sellPrice');
    const sectionEk = totalPriceForRow(sectionIndex, rowIndex, 'purchasePrice');
    const from_percentage: number = Math.round(((sectionUpe - sectionEk) / sectionUpe) * 100);

    return `${from_percentage || 0} %`;
};

const getSectionFromPercentage = (sectionIndex: number) => {
    const sectionUpe = totalPriceForSection(sectionIndex, 'sellPrice');
    const sectionEk = totalPriceForSection(sectionIndex, 'purchasePrice');
    const from_percentage: number = Math.round(((sectionUpe - sectionEk) / sectionUpe) * 100);

    return `${from_percentage || 0} %`;
};

const getTotalFromPercentage = () => {
    const from_percentage: number = Math.round(((props.sum?.sumSellPrice - props.sum?.sumPurchasePrice) / props.sum?.sumSellPrice) * 100);

    return `${from_percentage || 0} %`;
};

const getSectionLabels = (section: MatrixHeaderSection) => section.columns
    .flatMap(column => column.cells.map(cell => cell.labels))
    .flat()
    .filter((label, index, array) => array.indexOf(label) === index) || [];

const getSectionScalePrices = (section: MatrixHeaderSection) => section.columns
    .flatMap(column => column.cells)
    .filter(shouldProcessCell)
    .map(cell => cell.scalePrices)
    .filter(scalePrices => scalePrices)
    .flatMap(scalePrices => Object.keys(scalePrices || {}))
    .filter((key, index, array) => array.indexOf(key) === index)
    .join('/');

const matrixQuantitiesUpdated = () => {
    cellSelectionFinished.value = true;
};

onMounted(() => {
    closedSections.value = getClosedBranchesStorage() ?? [];
    inlineInputFirst();
    registerCellCallbacks(onStartCellSelection, onUpdateCellSelection, onFinishCellSelection);
    registerRowCallbacks(onStartRowSelection, onUpdateRowSelection, onFinishRowSelection);
    triggerScrollBoosterPlugin();
    $emitter.$on('updateTotalSums', matrixQuantitiesUpdated);
});

onUpdated(() => {
    if (scrollBooster) {
        (scrollBooster as any).updateMetrics();
    }
});

onBeforeUnmount(() => {
    triggerScrollBoosterPlugin(true);
    $emitter.$off('updateTotalSums', matrixQuantitiesUpdated);
});

watch(
    () => props.quickSelection,
    (value) => {
        if (!value) {
            inlineInputFirst();
        } else {
            closeInlineInput();
        }
    }
);

watch(
    () => props.data,
    () => {
        if (!props.quickSelection) {
            closeInlineInput();
            setTimeout(() => {
                inlineInputFirst();
            }, 0);
        }

        selectedRows.value = Array(totalRows.value).fill(false);
    }
);
</script>

<style lang="scss" scoped>
@import url('@css/components/matrix-content.scss');
</style>
