<template>
    <div :class="{'options': true, 'options__no': useStore().isNoStore() && pdp}">
        <div
            v-if="useStore().isVoStore()"
            v-role:not="RoleTypes.SUPPLIER"
            :class="{'options__container-wrapper': true, 'options__container-wrapper-no': useStore().isNoStore()}"
        >
            <div class="options__container-headline">
                {{$t('Matrix.cart')}}
            </div>
            <div class="options__container options__container--flyout">
                <MoleculeShoppingCartFlyout
                    :show-in-matrix="true"
                    :cart-id="cartId"
                    :is-cart-service="isCartService"
                />
            </div>
        </div>
        <MoleculeMatrixBranchCluster
            v-if="branchClustersAvailable && !nosTabSelected && useStore().isVoStore()"
            v-role:not="RoleTypes.SUPPLIER"
            :product-abstract-id="productAbstractId?.toString() ?? ''"
            :branch-cluster-selected="branchClusterSelected"
            :order-items="orderItems"
            :module-items="moduleItems"
            :module-name="moduleName"
            :allocation-id="allocationId"
            :cart-closed="cartClosed"
        />
        <div
            v-role:not="RoleTypes.SUPPLIER"
            :class="{'options__container-wrapper': true, 'options__container-wrapper-no': useStore().isNoStore()}"
        >
            <div class="options__container-headline">
                {{$t('Matrix.capture')}}
            </div>
            <div class="options__container spacing-right--s">
                <div class="options__item">
                    <AtomRadioButton
                        name="quick-selection"
                        :model-value="quickSelection ? 0 : 1"
                        size="s"
                        :items="[
                        { icon: 'mouse-pointer', width: 20 },
                        { icon: 'keyboard', width: 20 },
                    ]"
                        data-testid="matrixCaptureInput"
                        @update:model-value="emit('update:quickSelection', $event === 0)"
                    />
                </div>
                <div class="options__item" >
                    <AtomNumberInput
                        input-type="number"
                        :disabled="!quickSelection"
                        :model-value="quickSelectionCount"
                        :min="Number.MIN_SAFE_INTEGER"
                        :step="nosTabSelected && minStockSelected ? 1 : minPackagingUnit"
                        :allow-zero="false"
                        size="s"
                        :side-buttons="true"
                        @update:model-value="emit('update:quickSelectionCount', $event as number)"
                    />
                </div>
            </div>
        </div>

        <div :class="{'options__container-wrapper': true, 'options__container-wrapper-no': useStore().isNoStore()}">
            <div class="options__container-headline">
                {{$t('Matrix.matrix')}}
            </div>
            <div class="options__container">
                <div class="options__item options__always-active">
                    <AtomRadioButton
                        :model-value="groupByIndex"
                        name="matrix-grouping"
                        size="s"
                        :items="getItems()"
                        data-testid="matrixGroupingSwitch"
                        @update:model-value="emit('update:groupByIndex', $event)"
                    />
                </div>
                <div v-role:not="RoleTypes.SUPPLIER" class="options__item">
                    {{ $t('Matrix.reset_matrix') }}
                    <AtomButton
                        class="e2e-matrix-reset"
                        :disabled="cartClosed"
                        icon="rotate-ccw"
                        type="secondary"
                        size="s"
                        data-testid="matrixResetButton"
                        @click="emit('resetClicked')"
                    />
                </div>
                <div v-role:not="RoleTypes.SUPPLIER" class="options__line"/>
                <div v-role:not="RoleTypes.SUPPLIER" class="options__item options__edited">
                    <div :title="toggleTitle">
                        <AtomToggle
                            :model-value="editedToggleValue"
                            :disabled="editedToggleDisabled"
                            @update:model-value="updateMatrixEdited"
                        />
                    </div>
                    <div class="options__edited-text">{{ $t('Matrix.matrix_edited') }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import type { UserFlowStateUpdateParams } from '~/composables/types/api/cartConditions/userflow';
import type { MatrixModuleItem, MatrixOrderItem } from '~/composables/types/api/cartConditions/matrix';
import type { MatrixSumProps } from '~/composables/types/api/searchDiscover/getMatrix';
import { UserflowTypes } from '~/composables/types/userflowTypes';
import { RoleTypes } from '~/composables/types/roleTypes';
import useStore from '~/composables/service/useStore';

const props = withDefaults(defineProps<{
    pdp: boolean,
    groupByIndex: number,
    quickSelection: boolean,
    cartClosed: boolean,
    moduleItems: MatrixModuleItem[],
    orderItems: MatrixOrderItem[],
    moduleName: string | null,
    allocationId: string |null,
    quickSelectionCount: number,
    minPackagingUnit: number,
    cartId?: string,
    isCartService?: boolean,
    userFlowState: string,
    brandCode: string,
    modelCode: string,
    supplierId: string,
    branchClustersAvailable: boolean,
    nosTabSelected: boolean,
    minStockSelected: boolean,
    productAbstractId: string | null,
    branchClusterSelected: string | null
    sumData: MatrixSumProps[]
}>(), {
    cartId: '',
    isCartService: false,
});

const emit = defineEmits<{
    (event: 'update:groupByIndex' | 'update:quickSelectionCount', value: number): void;
    (event: 'update:quickSelection' | 'update:toggleStateChanged', value: boolean): void;
    (event: 'resetClicked'): void;
}>();

const {
    setUserFlowState
} = useUserFlowState();

const { $t } = useNuxtApp();

const editedToggleValue = ref(props.orderItems.length > 0 && props.userFlowState === UserflowTypes.DONE);
const editedToggleDisabled = ref(props.orderItems.length <= 0);

const quantitiesSet = (): boolean => props.sumData.some(sum => sum.productsCount > 0);

const getItems = () => [
    { text: 'Filiale', icon: 'home' },
    { text: 'Farbe', icon: 'droplet' },
];

const toggleTitle = computed(() => (!editedToggleDisabled.value ? $t('Matrix.edited_toggle_title') : ''));

const updateMatrixEdited = async(modelValue: boolean | undefined) => {
    editedToggleValue.value = Boolean(modelValue);

    const stateParams: UserFlowStateUpdateParams = {
        cartId: props.cartId,
        brandCode: props.brandCode,
        modelCode: props.modelCode,
        supplierId: props.supplierId,
    };
    const state = editedToggleValue.value ? UserflowTypes.DONE : UserflowTypes.EDITING;
    await setUserFlowState(stateParams, state);
    emit('update:toggleStateChanged', true);
};

watch(() => props.sumData, () => {
    editedToggleDisabled.value = !quantitiesSet();
    if (editedToggleDisabled.value) {
        editedToggleValue.value = false;
    }
});
</script>

<style lang="scss" scoped>
.options {
    width: 100%;
    justify-content: flex-start;
    margin: sp(s) 0;
    align-self: center;
    display: flex;

    @include helper-font-size(small);

    @include helper-breakpoint-media-max(xxl) {
        flex-wrap: wrap;
    }

    &__no {
        margin-top: 0;
    }

    &__always-active {
        cursor: pointer;
        pointer-events: all;
    }

    :deep(.cart-flyout-button) {
        margin-left: 0;

        li {
            list-style-type: none;

            input {
                z-index: 1;
                cursor: pointer;
                height: rem(50);

                @include helper-color-bg(white);
            }
        }

        .disabled input {
            cursor: not-allowed;

            @include helper-color(gray-2);
            @include helper-border-color(gray-2);
        }

        .disabled svg {
            @include helper-color(gray-2);
        }

        .input-icon {
            position: absolute;
            height: 100%;
            right: sp(s);
            pointer-events: none;
        }
    }

    :deep(.cart-flyout .flyout) {
        left: 0;
        right: auto;

        &::before {
            left: rem(40);
        }
    }

    &__container-headline {
        padding: 0 0 sp(xxs);
        text-align: left;
        font-weight: fw(medium);
        line-height: sp(m);

        @include helper-color(text-secondary);
    }

    &__item {
        align-items: center;
        margin-right: sp(xs);
        white-space: nowrap;
        font-size: fs(small);
        font-weight: fw(medium);
        max-height: rem(32);
        display: flex;

        @include helper-color(text-secondary);

        .button,
        .radio-button,
        .toggle-switch {
            margin-left: sp(xs);
        }
    }

    &__container {
        flex: 0.3;
        padding: sp(xs);
        justify-content: space-between;
        display: flex;

        @include helper-border();
        @include helper-border-radius(rem(5));

        @include helper-breakpoint-media-max(xxl) {
            flex: auto;
        }

        &--flyout {
            border: 0;
            padding: 0;
            height: 100%;
        }

        &-wrapper {
            flex: 1;

            &:first-child {
                :deep(.header__action),
                .options__item,
                .cart-flyout-button {
                    width: 100%;
                }
            }
        }

        &-wrapper-no {
            flex: 0;
        }
    }

    &__separator {
        width: rem(1);
        height: 150%;
        margin: rem(-8) sp(xs) rem(-8) 0;

        @include helper-color-bg(gray-2);
    }

    &__line {
        margin: rem(-8) sp(xs);

        @include helper-border-l();
    }

    &__edited {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: sp(xs);
    }

    &__edited-text {
        height: rem(16);

        @include helper-font-size(small);
        @include helper-font-weight(medium);
        @include helper-font-line-height(4);
    }
}
</style>
